import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { LoaderComponent } from './layout/loader/loader.component';
import { MaterialModule } from '../material.module';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { SsnMaskDirective } from './directives/ssn-mask.directive';
import { OnlylettersDirective } from './directives/onlyletters.directive';
import { OnlyLettersAndNumbersDirective } from './directives/only-letters-and-numbers.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { AuthComponent } from './components/auth/auth.component';
import { OnlyDecimalDirective } from './directives/only-decimal.directive';
import { ShowAuthDirective } from './directives/show-auth.directive';
import { FilterCharsDirective } from './directives/filter-chars.directive';
import { MatConfirmDialogComponent } from './components/mat-confirm-dialog/mat-confirm-dialog.component';
import { ChangeToUpperDirective } from './directives/change-to-upper.directive';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SsnFieldComponent } from './components/ssn-field/ssn-field.component';
import { ShowWindowComponent } from './components/show-window/show-window.component';
//import { BlockPasteDirective } from './directives/BlockPaste.directive';


@NgModule({
  declarations: [
    LoaderComponent,
    PhoneMaskDirective,
    SsnMaskDirective,
    OnlylettersDirective,
    OnlyLettersAndNumbersDirective,
    OnlyNumbersDirective,
    AuthComponent,
    OnlyDecimalDirective,
    ShowAuthDirective,
    FilterCharsDirective,
    MatConfirmDialogComponent,
    ChangeToUpperDirective,
    SanitizeHtmlPipe,
    SsnFieldComponent,
    ShowWindowComponent
    //BlockPasteDirective       
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LoaderComponent,
    PhoneMaskDirective,
    SsnMaskDirective,
    OnlylettersDirective,
    OnlyLettersAndNumbersDirective,
    OnlyNumbersDirective,
    OnlyDecimalDirective,
    ShowAuthDirective,
    AuthComponent,
    FilterCharsDirective,
    ChangeToUpperDirective,
    SanitizeHtmlPipe,
    SsnFieldComponent,
    ShowWindowComponent
    //BlockPasteDirective
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true }]
})
export class SharedModule { }
