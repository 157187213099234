<div class="container" style="padding-top:100px;margin-bottom:300px;">
  <div class="card mat-elevation-z8 mt-5">
    <div class="card-header d-flex justify-content-center">
      <mat-card-title>
        Reset Password
      </mat-card-title>
    </div>
    <div class="container card-body" style="font-size:small">
      <section class="row">
        <section class="col">
          <p class="text-left"> To reset your password, enter the user name you use to sign in to HENS and click the 'Reset password' button.</p>
          <p class="text-left"> An email message will be sent to the email address you used when creating the account.</p>
          <p class="text-left">If you do not receive your email within five minutes check your spam folder.</p>
        </section>
      </section>
      <section class="row">
        <section class="col-6">
          <mat-card>
            <section class="row p-4">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Please Enter UserName</mat-label>
                  <input matInput [formControl]="userName" maxlength="30" changeToUpperCase appFilterChars allowedChars="[a-zA-Z0-9-_]" required>
                  <mat-error *ngIf="userName.errors?.required">Username required.</mat-error>
                </mat-form-field>
                <a mat-raised-button routerLink=''>Back to Login</a>
                <!-- (click)="router.navigate([''])" -->
              </div>
              <div class="col pt-3">
                <div class="d-flex">
                  <button [disabled]="userName.value.length <= 0 || processing" mat-raised-button color="primary" (click)="onForgotPassword()">
                    <div class="d-flex">
                      <span>Reset password</span>
                      <mat-spinner *ngIf="processing" color="warn" diameter="20" class="ml-2 mt-2"></mat-spinner>
                    </div>
                  </button>
                 
                </div>
              </div>
            </section>
          </mat-card>
        </section>
      </section>



    </div>
  </div>
</div>
