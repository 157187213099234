
<div *ngIf="!backToSearch" class="container" style="padding-top:100px">
  <form [formGroup]="authForm" (ngSubmit)="submitForm()">
    <fieldset [disabled]="isSubmitting">
      <div class="card-body" style="padding-left:300px">
        <div class="card mat-elevation-z5 mt-5" style="width:70%">
          <div class="card-header d-flex justify-content-center">
            <mat-card-title>
              Login
            </mat-card-title>
          </div>
          <div class="container card-body d-block justify-content-center">

            <div class="row justify-content-center align-content-center">
              <mat-form-field appearance="outline">
                <mat-label>User Name</mat-label>
                <input id="userName" matInput placeholder="User Name" formControlName="UserName" type="text" required>

                <mat-error id="userNameRequiredError" *ngIf="authForm.get('UserName').value == ''">
                  User Name is <strong>required</strong>
                </mat-error>
                <mat-error id="userNameInvalidError" *ngIf="authForm.get('UserName')?.invalid && authForm.get('UserName').value != ''">
                  Please enter valid characters
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row justify-content-center align-content-center">

              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input id="password" matInput placeholder="Password" formControlName="Password" type="password" required>

                <mat-error id="passwordRequiredError" *ngIf="authForm.get('Password').value == ''">
                  Password is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row justify-content-center align-content-center">
              <button id="submit" mat-raised-button color="primary" [disabled]="!authForm.valid" type="submit">
                <mat-spinner class="position-absolute" diameter="25" *ngIf="formloading" color="Accent" mode="indeterminate"></mat-spinner>
                <div>Login</div>
              </button>
            </div>
          

          </div>

        </div>
      </div>
    </fieldset>
  </form>
  <br />
</div>
<style>
  .SystemMessageHeader {
  /*font: 15px Roboto,Verdana;*/
  font: Roboto, Verdana;
  font-size: 15px;
  color: #ff0000;
  font-weight: bold;
  text-align: center;
}


.SystemMessageDefault {
  font-size: 13px;
  font-family: Roboto, Verdana;
  /*font: @font;*/
  font-weight: bold;
  text-align: center;
  color: #4E5242;
}

</style>
