<nav class="navbar  navbar-expand-sm navbar-dark bg-dark mt-4 app-footer">
  <div class="container">
    <a class="navbar-brand order-md-last" href="#"><img src="assets/images/OH_OhioGovLogo_White.png"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          
              
          <a class="nav-link" href="#">
            &copy; Copyright {{year}} by Ohio Department of Aging - version {{version}} All Rights Reserved.
          </a>      
        </li>
       
      </ul>
    </div>
  </div>
  </nav>
