import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../models';
import { UserService, DataService, AlertsService } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private dataService: DataService,
    private alertService: AlertsService) { }

  isAdmin = false;
  isshowNFmenu = false;
  ishowmnguser = false;
  isshownewdoc = false;
  agencyname = "";
  
  profileUser: User;

  ngOnInit() {
    
    this.userService.currentUser.subscribe((userData: User) => {      
      this.profileUser = userData;   
      this.getAgencyNameandRolespermission();      
    });    
    this.alertService.clearLoader();
  }

  getAgencyNameandRolespermission() {
    if (this.profileUser == null) {
      return;
    }
    var UserType = this.profileUser.userType
    if (this.profileUser.userRoles != undefined && this.profileUser.userRoles.indexOf('ADMIN') >= 0) {
      this.isAdmin = true;
    }
    if (this.profileUser.userRoles != undefined && this.profileUser.userRoles.indexOf('GENERAL') >= 0) {

      if (UserType == 'PAA' || UserType == 'NF' || UserType == 'HOSPITAL') {
        this.isshownewdoc = true;
      }
      if (UserType == 'PAA' || UserType == 'NF' || UserType == 'HOSPITAL' || UserType == 'ODA') {
        this.isshowNFmenu = true;
      }
    }
    if (this.profileUser.odaAgency != undefined && this.profileUser.odaAgency != "")
      this.agencyname = this.profileUser.odaAgency;
    else
      this.agencyname = this.profileUser.userAgencyName;
  }

  newDocument() {
    this.dataService.clearSelectedDocument();
    this.router.navigate(['new-document']);
    this.alertService.clearLoader();
  }

  openWizard() {
    this.router.navigate(['new-document-wizard']);
  }

  nfChnageRequest() {
    this.router.navigate(['nf-change-request']);
  }

  openprofile() {
    this.router.navigate(['profile']);
  }

  openHelp() {
    this.router.navigate(['user-guide']);
  }

  openHome() {
    this.router.navigate(['home']);
  }

  logout() {
    localStorage.clear();
    this.alertService.clearLoader();
    this.dataService.clearSelectedDocument();
    this.userService.purgeAuth();
  }
}

