import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, ForgotPassword } from '../models';
import { JwtService } from './jwt.service';
import { ApiService } from './api.service';
import { LoaderService } from './loader.service';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, catchError } from 'rxjs/operators';
import { timer } from 'rxjs/observable/timer';
import { SessionTransaction } from '../models/session-transaction';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private router: Router,
    private loaderService: LoaderService) { }

  populate() {

    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get('user/VerifyUser')
        .subscribe(
          data => this.setAuth(data),
          err => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {

    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(user.auth_token);
    user = this.jwtService.decodeToken(user.auth_token);
    // Set current user data into observable
    //console.log(user);//to do remove this 
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    this.loaderService.hideLoader();
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    sessionStorage.clear();
    localStorage.clear();

    //this.router.navigateByUrl('');

    const source = timer(1000, 2000);
    const subscribe = source.subscribe(val => {
      if (!this.jwtService.getToken()) {
        if (this.router.url === '/') {
          sessionStorage.clear();
          //this.router.navigateByUrl('');
        }
      }
    });
    this.loaderService.hideLoader();
  }

  purgeAuthFromLogin() {
    localStorage.clear();
    sessionStorage.clear();
    this.loaderService.hideLoader();
    this.jwtService.destroyToken();
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);

  }

  attemptAuth(type, credentials): Observable<User> {
    const route = (type === 'login') ? 'token' : '';
    return this.apiService.postAuth(route, credentials)//'/users' +
      .pipe(map(
        data => {
          this.setAuth(data);
          return data;
        }
      ));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  update(user): Observable<User> {

    return this.apiService
      .put('User/UpdateUser', user)
      .pipe(map(data => {
        return data;
      }));
  }

  manageUser(): Observable<User> {
    return this.apiService.get('User/GetUsers')
      .pipe(map(data => {
        return data;
      }));
  }

  updateUser(user): Observable<User> {
    return this.apiService
      .put('User/UpdateUser', user)
      .pipe(map(data => {
        return data;
      }));
  }

  add(user): Observable<User> {
    return this.apiService
      .post('User/SaveUser', user)
      .pipe(map(data => {
        return data;
      }));
  }

  forgetPassword(email): Observable<ForgotPassword> {
    let userEmail = { US_EMAIL_ADDRESS: email };
    return this.apiService.post('User/ForgetPassword', userEmail).pipe(map(data => { return data; }));
  }

  updatePassword(newPassword): Observable<ForgotPassword> {
    return this.apiService.post('User/UpdatePassword', newPassword).pipe(map(data => { return data; }));
  }

  register(user) {
    return this.apiService
      .post('User/Register', user)
      .pipe(map(data => {
        return data;
      }));
  }

  refreshUser(jti): Observable<User> {
    let token = { sessionId: jti };
    return this.apiService.post('User/RefershToken', token).pipe(map(data => {
      let refreshToken = JSON.parse(data);
      this.jwtService.saveToken(refreshToken.auth_token);
      return JSON.parse(data);
    }));
  }

  checkLastTransactionTime(jti): Observable<SessionTransaction> {
    let token = { sessionId: jti };
    return this.apiService.post('User/CheckLastTransactionTime', token).pipe(map(data => {
      return data;
    }));
  }

  getAssessorNames(user: string, usertype: string): Observable<any> {
    return this.apiService.get(`user/GetAssessorNames/?userName=${user}&usertype=${usertype}`);
  }

  getuserdatabyusername(username): Observable<any> {
    return this.apiService.get('User/GetUserByName/GetUserlistAsync/' + username)
      .pipe(map(data => { return data; }));
  }

  updateUserProfiledata(userinfo): Observable<any> {
    return this.apiService.post('User/Updateuserprofileasync/UpdateUserAsync', userinfo)
      .pipe(map(data => {
        return data;
      }));
  }

  chnagepassword(userinfo): Observable<any> {

    return this.apiService.post('User/ChangePasswordasync/changespassword', userinfo)
      .pipe(map(data => {
        return data;
      }));
  }

  checkPassword(userinfo) {
    return this.apiService.post('User/CheckPasswordasync/CheckPassword', userinfo)
      .pipe(map(data => {
        return data;
      }));

  }

  ValidateCurrentPasswordAsync(userinfo) {
    return this.apiService.post('User/ValidateCurrentPassword?username=' + userinfo.username + '&password=' + userinfo.password)
      .pipe(map(data => {
        return data;
      }));
  }

  clearWizardSession(): void {
    this.apiService.post('ConnectFromWizard/ClearContext').subscribe(msg => {
      console.info('wizard sesion cleared')
    });
  }

  createNewSession(jti): Subject<User> {
    let complete_event = new Subject<User>();
    this.refreshUser(jti).subscribe(user => {
      this.setAuth(user);
      complete_event.next(user);
    }, err => {
        complete_event.next(null);
    });
    return complete_event;
  }

}
