import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService, User, AlertsService } from '../../shared';
import { timer, of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  authForm: FormGroup;
  isSubmitting = false;
  formloading = false;
  backToSearch: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private alertsService: AlertsService
  ) {
    this.authForm = this.fb.group({
      UserName: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60), Validators.required]),
      Password: new FormControl('', [Validators.maxLength(60), Validators.required])
    });

    this.backToSearch = false;
  }

    ngOnInit() {        
      this.alertsService.clearLoader();
  }
  

  submitForm() {
    //this.alertsService.();
    this.disableForm(true);
    this.isSubmitting = true;
    const credentials = this.authForm.value;    
    this.userService
      .attemptAuth('login', credentials)
      .subscribe(response => {
        this.router.navigateByUrl('/schedules');
      },
        err => {
          console.error('error while login ', err);
          this.alertsService.showErrorMessage('Your login is invalid.  Please try again','Login Failed.');
          this.disableForm(false);
        }, () => {
        });
    this.isSubmitting = false;
  }

  private disableForm(disable: boolean): void {
    this.formloading = disable;
    if (disable) {
      this.authForm.get('UserName').disable();
      this.authForm.get('Password').disable();
    } else {
      this.authForm.get('UserName').enable();
      this.authForm.get('Password').enable();
    }
  }

  
}
