import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdgroupCheckGuardGuard } from './shared/common/adgroup-check-guard.guard';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login-routing.module').then(m=>m.LoginRoutingModule),
  },
  {
    path: '', //schedules
    loadChildren: () => import('./schedules-public-view/schedules-public-view.module').then(m => m.SchedulesPublicViewModule)

  },
  {
    path: 'search-facility',
    loadChildren: () => import('./schedules-public-view/schedules-public-view.module').then(m => m.SchedulesPublicViewModule)
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
