import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { UserService, LoaderService, JwtService } from '../../services';
import { interval } from 'rxjs/observable/interval';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, OnDestroy {
  showAuthRefresh;
 
  private subscription: Subscription;

  private readonly _timeoutSeconds: number;
  _count: number;
  private timerSubscription: Subscription;
  private timer: Observable<number> = interval(1000);
  private _remainSeconds = new Subject<number>();


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private loaderService: LoaderService,
    private jwtService: JwtService) {
    this._timeoutSeconds = 300;
  }

  ngOnInit() {
    this.subscription = this.authService.authState
      .subscribe(show => {
        this.showAuthRefresh = show;
        if (show) {
          this.startTimer();
        } else {
          this.stopTimer();
        }
      });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  sessionExtend() {
    this.userService.refreshUser(
        this.jwtService.decodeToken(
          sessionStorage.getItem('HENS3')).jti).subscribe(res => {
            this.loaderService.hideLoader();
            this.authService.hideAuthRefresh();
        });
  }

  hideExtend() {
    this.authService.hideLogoutAuthRefresh();
  }

  startTimer() {
    //this.stopTimer();
    this._count = this._timeoutSeconds;
    this.timerSubscription = this.timer.subscribe(n => {
      if (this._count > 0) {
        this._count--;
        this._remainSeconds.next(this._count);
      } else {
        this.hideExtend();
      }
    });
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resetTimer() {
    this.startTimer();
  }
}
