
<nav class="navbar navbar-top-fixed navbar-expand-md navbar-light bg-white border-bottom box-shadow mb-3">
  <div class="container">
    <h3 class="my-auto"><a class="navbar-brand" href="#"><img src="assets/images/ODA_Logo_340x88_Transparent.png" /></a></h3>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse w-100 flex-md-column" id="navbarCollapse">
      <form class="form-inline ml-auto">
        <div class="input-group">
          <h4 class="title">Long-Term Care PCR Testing Schedule</h4>
        </div>
      </form>
      <ul class="navbar-nav ml-auto large mb-2 mb-md-0" style="align-items: baseline;">        
        <li class="nav-item active" mdbDropdown *appShowAuth="true">
          <a class="dropdown-toggle waves-light" mdbDropdownToggle role="button" mdbWavesEffect>
            <span class="caret">{{profileUser.userName}}</span>
          </a>
          <ul class="dropdown-menu dropdown-primary">            
            <li class="dropdown-item" routerLink="profile"><mat-icon>perm_identity</mat-icon> Profile</li>
            <li class="dropdown-divider"></li>
            <li class="dropdown-item" routerLink="logoff" (click)="logout()"><mat-icon>exit_to_app</mat-icon> Logoff</li>
          </ul>
        </li>
      </ul>
    </div>
  
  </div>
</nav>

