import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import * as $ from 'jquery';
import { UserService, LoaderService, DataService, AuthService } from '../app/shared/services';
import { timer } from 'rxjs/observable/timer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private loaderService: LoaderService,
    private dataService: DataService,
    private authService: AuthService) {

    
  
  }

  showMatStepper: boolean = false;
  showConsumerInfo: boolean = true;
    ngOnInit() {
    
    this.userService.populate();   
   
  }

  ngAfterViewInit() {

    this.router.events
      .subscribe((event) => {

        if (event instanceof NavigationStart) {
          this.loaderService.showLoader();
        } else {
          this.loaderService.hideLoader();
        }

      });

  }
}
